import React from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { deleteCookie, getCookie, getCookies } from "cookies-next";
import { signIn, signOut, SignOutParams, useSession } from "next-auth/react";

import Header from "components/Header";
import Aside from "components/Aside";
import { useAuth } from "hooks/use-auth";
import { URI_LOGIN, URL_ADMIN_USERS_LIST } from "constants/urls";
import { appPageTitleSuffix, AuthProviders } from "constants/index";
import { useBookingsPlatformStore } from "store/booking-platform";
import { classNames } from "lib/utils";
import Loader from "components/common/Loader";
import ReportIssue from "../../clients/ReportIssue/ReportIssue";

export interface LayoutProps {
    children?: React.ReactNode;
    title?: string;
    noPadding?: boolean;
    hideReportIssue?: boolean;
}

export default function Layout({
    children,
    title = "",
    noPadding = false,
    hideReportIssue = false
}: LayoutProps) {
    const router = useRouter();
    const { user, isFeatureEnabled } = useAuth();
    const { data: session } = useSession();
    const impersonatedUserProfile = session?.impersonatedUserProfile;
    const {
        initLoadingHotelComponent,
        jumpVisibilityState,
        currentSupplierId,
        hiddenList
    } = useBookingsPlatformStore();

    const routerSupplierType = router.query.supplierType as string[];

    const isHiddenNavigation =
        currentSupplierId &&
        routerSupplierType?.[1] === "hotel" &&
        (jumpVisibilityState ||
            (!jumpVisibilityState && initLoadingHotelComponent) ||
            hiddenList);

    const handleDetachUser = async () => {
        const toDetach =
            session?.accessToken !== getCookie("originalGoogleToken");
        const signOutOptions: SignOutParams<any> = {};
        const backLink = "";
        if (!toDetach) {
            signOutOptions.callbackUrl = `${URI_LOGIN}${backLink}`;
        } else {
            signOutOptions.redirect = false;
        }
        await signOut(signOutOptions);
        if (toDetach) {
            try {
                await signIn(AuthProviders.LOGIN_AS_ADVISOR, {
                    accessToken: getCookie("originalGoogleToken")
                }).then(() => {
                    const cookies = Object.keys(getCookies());
                    cookies.forEach(key => deleteCookie(key));
                });
            } catch (error) {
                console.log(
                    "Error handler was fired on the getting user token",
                    error
                );
            } finally {
                router.push(URL_ADMIN_USERS_LIST, undefined, {
                    shallow: true
                });
            }
        }
    };
    return (
        <div className="min-h-screen">
            <Head>
                <title>{title ? `${title} - ${appPageTitleSuffix}` : ""}</title>
            </Head>
            <style jsx global>{`
                .container {
                    max-width: 65rem;
                    margin: 1.5rem auto;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            `}</style>
            {!!impersonatedUserProfile && (
                <div
                    className="flex-shrink-0 flex px-4 lg:px-6 py-4 lg:pb-3 lg:pt-[11px] bg-link text-white lg:fixed top-0 left-0 right-0 z-[100] pointer-events-none hidden lg:pointer-events-auto lg:flex impersonate-wrapper"
                    data-testid="impersonate-wrapper"
                >
                    <div
                        className="flex items-center text-medium font-bold mr-2 lg:mx-auto"
                        data-testid="impersonate-content"
                    >
                        You are impersonating{" "}
                        {impersonatedUserProfile?.firstName}{" "}
                        {impersonatedUserProfile?.lastName} (
                        {impersonatedUserProfile?.email as string})
                    </div>
                    <button
                        className="inline-flex ml-auto lg:ml-0 items-center justify-center px-4 py-3 font-bold bg-white text-main text-small rounded-3xl border border-stroke focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-600"
                        onClick={handleDetachUser}
                        data-testid="impersonate-detach"
                    >
                        Detach
                    </button>
                </div>
            )}
            <Aside />
            <div
                id="main-container"
                className={"min-h-screen h-full lg:pl-64 flex flex-col flex-1"}
            >
                <Header />
                <main
                    className={classNames(
                        "flex-1",
                        isHiddenNavigation && "invisible"
                    )}
                >
                    <div className={noPadding ? "" : "p-6 md:py-12 md:px-16"}>
                        {children}
                        {!hideReportIssue && (
                            <div className="mt-4">
                                <ReportIssue />
                            </div>
                        )}
                    </div>
                </main>
                {isHiddenNavigation && <Loader isSpin className={"lg:ml-64"} />}
            </div>
        </div>
    );
}
