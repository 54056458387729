import {
    Certification,
    CertificationConfig,
    CertificationFeature
} from "lib/types";
import Link from "next/link";

export const CERTIFICATIONS: { id: Certification; name: string }[] = [
    { id: 1, name: "Certified" },
    { id: 2, name: "Advanced" },
    { id: 3, name: "Pro" },
    { id: 5, name: "Fora X" }
];

export const CERTIFICATION_CONFIG: {
    [key: number]: CertificationConfig;
} = {
    0: {
        color: "#E7E7E7",
        fontColor: "#BBBBBB",
        name: "Not yet certified",
        shortName: "All",
        limit: "You have from Jan 1 to Dec 31 to earn status",
        nextLevel: 1,
        features: [
            "portal",
            "training",
            "foras-nyc-conference",
            "booking-bootcamp",
            "advisor-login"
        ]
    },
    1: {
        color: "#749F90",
        fontColor: "#525B58",
        name: "Certified",
        shortName: "Certified",
        limit: "You have from Jan 1 to Dec 31 to earn status",
        tasksToAchieveLevel: [
            "Complete at least one booking",
            <>
                Complete{" "}
                <Link
                    href="/training/certified/certified-quiz"
                    className="text-link"
                >
                    Certified quiz
                </Link>
            </>
        ],
        nextLevel: 2,
        features: [
            "portal",
            "training",
            "badge",
            "foras-nyc-conference",
            "fora-organized-site-visits",
            "booking-bootcamp",
            "advisor-login"
        ],
        featureValues: {
            "commission-split": "70/30"
        }
    },
    2: {
        color: "#689EB2",
        fontColor: "#3E494F",
        name: "Advanced",
        shortName: "Advanced",
        limit: "You have from Jan 1 to Dec 31 to earn status",
        tasksToAchieveLevel: [
            "Complete at least five bookings",
            <>
                Complete Advanced training and{" "}
                <Link
                    href="/training/advanced/advanced-take-the-advanced-quiz"
                    className="text-link"
                >
                    quiz
                </Link>
            </>,
            "Create a portfolio"
        ],
        nextLevel: 3,
        features: [
            "portal",
            "training",
            "badge",
            "foras-nyc-conference",
            "fora-organized-site-visits",
            "path-to-pro-programming",
            "booking-bootcamp",
            "advisor-login"
        ],
        featureValues: {
            "commission-split": "70/30"
        }
    },
    3: {
        color: "#42413F",
        fontColor: "#000",
        name: "Pro",
        shortName: "Pro",
        limit: "You have from Jan 1 to Dec 31 to earn status",
        tasksToAchieveLevel: [
            "Demonstrate $100k in bookings within the calendar year",
            "Host five client reviews on foratravel.com",
            "Book at least 10 clients all time"
        ],
        nextLevel: 5,
        nextLevelPrefix: "invite-only",
        features: [
            "portal",
            "training",
            "badge",
            "foras-nyc-conference",
            "fora-organized-site-visits",
            "fora-and-industry-fam-trips",
            "path-to-pro-programming",
            "pro-forum-space",
            "mentorship",
            "local-partner-events",
            "advisor-login",
            "virtuoso-login",
            "preferred-partners",
            "booking-bootcamp",
            "office-hours",
            "email-priority-response-times",
            "iata-card",
            "internal-flight-desk"
        ],
        featureValues: {
            "commission-split": (
                <>
                    <div className="mb-1">
                        <div>70/30</div> (up to $300k)
                    </div>
                    <div>
                        <div>80/20</div> (after $300k)
                    </div>
                </>
            )
        }
    },
    5: {
        color: "#673535",
        fontColor: "#000",
        name: "Fora X",
        shortName: "Fora X",
        limit: "You have from Jan 1 to Dec 31 to earn status",
        tasksToAchieveLevel: [
            "Demonstrate $500k+ in bookings within the calendar year, subject to change. Represents top 3% of Fora Advisors.",
            "Agree to support Fora's advisor community through mentorship, additional leadership and training opportunities."
        ],
        tasksToKeepLevel: [
            "Demonstrate significant booking growth in the calendar year. Volume must be within top 3% of Fora Advisors."
        ],
        features: [
            "portal",
            "training",
            "badge",
            "foras-nyc-conference",
            "fora-organized-site-visits",
            "fora-and-industry-fam-trips",
            "path-to-pro-programming",
            "pro-forum-space",
            "mentorship",
            "business-planning",
            "local-partner-events",
            "direct-contact-with-fora-hq-team",
            "financial-management-advice",
            "advisor-login",
            "virtuoso-login",
            "preferred-partners",
            "virtuoso-travel-week",
            "industry-conferences",
            "booking-bootcamp",
            "office-hours",
            "email-priority-response-times",
            "iata-card",
            "press-opportunities",
            "internal-flight-desk"
        ],
        featureValues: {
            "commission-split": "80/20"
        }
    }
};

export const CERTIFICATION_FEATURES: CertificationFeature[] = [
    {
        id: "general",
        name: "General",
        features: [
            { id: "portal", name: "Access to Fora Portal" },
            { id: "training", name: "Access to Fora training" },
            { id: "badge", name: "Status badge" },
            {
                id: "commission-split",
                name: "Commission split*"
            }
        ]
    },
    {
        id: "network-with-us",
        name: "Network with us",
        features: [
            {
                id: "foras-nyc-conference",
                name: "Invite to Fora's NYC conference"
            },
            {
                id: "fora-organized-site-visits",
                name: "Access to Fora organized site visits"
            },
            {
                id: "fora-and-industry-fam-trips",
                name: "Access to Fora and industry FAM trips"
            }
        ]
    },
    {
        id: "mentorship",
        name: "Mentorship",
        features: [
            {
                id: "path-to-pro-programming",
                name: "Path to Pro programming including sessions on DMC's, itinerary development and client development"
            },
            { id: "pro-forum-space", name: "Pro Forum space" },
            {
                id: "mentorship",
                name: "Mentorship from Fora X members (application required)"
            },
            {
                id: "business-planning",
                name: "Business planning and coaching in partnership with Fora HQ"
            }
        ]
    },
    {
        id: "fora-hq",
        name: "Fora HQ",
        features: [
            {
                id: "local-partner-events",
                name: "Invites to local partner events with Fora HQ"
            },
            {
                id: "direct-contact-with-fora-hq-team",
                name: "Access to direct contact with Fora HQ team"
            },
            {
                id: "financial-management-advice",
                name: "Financial management advice in partnership with Fora HQ"
            }
        ]
    },
    {
        id: "travel-partner-perks",
        name: "Travel Partner Perks",
        features: [
            {
                id: "advisor-login",
                name: "Access to Expedia TAAP, Tablet Pro, & more"
            },
            {
                id: "internal-flight-desk",
                name: "Access to Fora's internal flight desk"
            },
            {
                id: "virtuoso-login",
                name: "Virtuoso login, profile & training"
            },
            {
                id: "preferred-partners",
                name: "Individual logins to specific preferred partners"
            },
            {
                id: "virtuoso-travel-week",
                name: "Access to Virtuoso Travel Week"
            },
            {
                id: "industry-conferences",
                name: "Access to invite-only industry conferences"
            }
        ]
    },
    {
        id: "support",
        name: "Support",
        features: [
            { id: "booking-bootcamp", name: "Booking Bootcamp - Office Hours" },
            { id: "office-hours", name: "Pro only Office Hours" },
            {
                id: "email-priority-response-times",
                name: "Priority response times from support@fora.travel"
            }
        ]
    },
    {
        id: "additional-perks",
        name: "Additional Perks",
        features: [
            { id: "iata-card", name: "IATA card" },
            {
                id: "press-opportunities",
                name: "Prioritized for press opportunities"
            }
        ]
    }
];
